import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from "./auth";
import displayReducer from './display';
import listReducer from './list';
import loadReducer from "./load";
import messagesReducer from "./messages";
import singleFeducer from "./single";
// import initialState from "../initialState";

const reducer = combineReducers({
  auth: authReducer,
  display: displayReducer,
  form: formReducer,
  list: listReducer,
  load: loadReducer,
  messages: messagesReducer,
  single: singleFeducer,
});

export default reducer;