import constants from '../constants';
import { addMessage } from './messages';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import beep from 'assets/audio/beep';
import * as moment from 'moment-timezone';

export const authSetup = () => ({
  type: constants.AUTH_SETUP,
})

export const login = (username, password) => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  const formData = new FormData();
  formData.append('json', JSON.stringify({username, password}));

  const url = handleCiviURLBuild('Authorization','authorize');

  return fetch(url, {
    method: "POST",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error)
     throw new Error(json.error_message);
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        accessCivicrm: json.values.access_civicrm,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    beep.play();
    dispatch(checkin(json.values.contact_id));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const barcodeLogin = (barcode) => dispatch => {

  dispatch({
    type: constants.TRY_LOGIN,
  });

  const formData = new FormData();
  formData.append('json', JSON.stringify({ barcode }));

  const url = handleCiviURLBuild('Authorization','barcodeauthorize');

  return fetch(url, {
    method: "POST",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error)
     throw new Error(json.error_message);
    dispatch({
      type: constants.LOGIN,
      value: {
        loggedInApiKey: json.values.api_key,
        contactId: json.values.contact_id,
        accessCivicrm: json.values.access_civicrm,
        contact: json.values.contact,
      }
    });
    // dispatch(addMessage('Logged in', 'success'));
    beep.play();
    dispatch(checkin(json.values.contact_id));
    return json;
  })
  .catch(error => {
    dispatch({
      type: constants.FAIL_LOGIN,
    });
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
};

export const logout = () => dispatch => {
  dispatch({
    type: constants.LOGOUT,
  });
}

const checkin = contact_id => dispatch => {

  const now = moment().tz("America/Los_Angeles");

  const json = {
    sequential: 1,
    activity_type_id: '164', // Kiosk check in
    target_contact_id: contact_id,
    status_id: 'Completed',
    activity_date_time: now.format('YYYY-MM-DD HH:mm:ss'),
    subject: "Room Kiosk - Check In",
  };
  // console.log(json);

  const formData = new FormData();
  formData.append('json', JSON.stringify(json));

  const url = handleCiviURLBuild('Request','create');

  return fetch(url, {
    method: "POST",
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: formData,
  })
  .then(response => response.json())
  .then(json => {
    // console.log(json);
    if (json.is_error) {
     throw new Error(json.error_message);
    }
    dispatch({
      type: constants.CHECK_IN,
      value: Object.values(json.values)[0],
    })
    return json.values[0];
  })
  .catch(error => {
    dispatch(addMessage(error.message, 'error'));
    return false;
  });
}