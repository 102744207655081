import React from 'react';
import preval from 'preval.macro';

const About = props => (
  <div className="About">
    <dl>
      <dt>Build Date</dt>
      <dd>
        {preval`
          const moment = require('moment-timezone');
          const now = moment().tz("America/Los_Angeles");
          const format = 'YYYY-MM-DD HH:mm:ss';
          module.exports = now.format(format);
        `}
      </dd>
      <dt>Name</dt>
      <dd>Batman</dd>
      <dt>Location</dt>
      <dd>Smile</dd>
      <dt>Catch Phrase</dt>
      <dd>To the Bat Mobile, Robin!</dd>
    </dl>
  </div>
);

export default About;