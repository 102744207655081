import store from 'store';
import * as moment from 'moment-timezone';

export const URLBuild = (entity, action, obj = null) => {

  const state = store.getState();
  const { siteURL, siteKey, apiKey } = state.auth;

  const url = siteURL+'?'+
    'entity='+encodeURIComponent(entity)+'&'+
    'action='+encodeURIComponent(action)+'&'+
    'key='+encodeURIComponent(siteKey)+'&'+
    'api_key='+encodeURIComponent(apiKey)+'&'+
    (obj === null ? '' : 'json='+encodeURIComponent(
      JSON.stringify(obj)
    )+'&')+
  '';
  return url;
}

export const registrationStatus = event => {


	const { custom_1246, custom_1248, is_online_registration } = event;
	if ('api.Participant.get' in event && event['api.Participant.get'].values.length>0) {
		return custom_1246==='1' || custom_1246==='2' ? 'registereddropin' : 'registered';
	} else if (custom_1246==='1' || custom_1246==='2') {
		return 'dropin';
	} else if (custom_1248==='1') {
		return 'closed';
	} else if (is_online_registration==='1') {
		return 'onlineregistration';
	} else {
		return 'noonlineregistration';
	}
}

export const participationStatus = (participation, statusMap) => {

	/**
	 * Current Event status totals as for 12/28/2018
	 * --------------------
	 * select status_id, pst.label, count(*) 
	 * from civicrm_participant p 
	 * join civicrm_participant_status_type pst 
	 * 	on p.status_id=pst.id 
	 * group by status_id 
	 * order by count(*) desc
	 * --------------------
	 * 2	Attended	66053
	 * 3	No-show	5479
	 * 18	Dropped In	3836
	 * 16	Confirmed	1444 -- registered
	 * 4	Cancelled	1130
	 * 17	Reminded	1101 -- registered
	 * 1	Registered	922 -- registered
	 * 7	Waitlisted	357 -- waitlisted
	 * 23	Waitlist - Emailed	177 -- waitlisted
	 * 19	Paid	151 -- registered
	 * 12	RSVP Deadline Has Expired	66
	 * 22	Waitlist - Called	21 -- waitlisted
	 * 6	Pending (incomplete transaction)	15
	 * 20	Seniors Helping Seniors (SHS)	12
	 */
	
		const status_id = 'status_id' in participation ? participation.status_id : participation.participant_status_id;
	
		// we don't have this value in our status map
		if (!(status_id in statusMap)) return 'error';
	
		// get the status
		const status = statusMap[status_id];
	
		// currently registered
		if (['1','16','17','19'].includes(status.id))
			return 'registered';

		// pending approval
		if (['8'].includes(status.id))
			return 'pendingapproval';
	
		// currently waitlisted
		if (['7', '23', '22'].includes(status.id))
			return 'waitlisted';
	
		if (['25'].includes(status.id))
			return 'pendingattended';

		if (['2'].includes(status.id))
			return 'attended';

		if (['4', '24'].includes(status.id))
			return 'cancelled';
		
		return 'hide';
	}

export const dateDisplay = event => {
	const start_date = moment.tz(event.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	const end_date = event.event_end_date===null || event.event_end_date==='' ? null : moment.tz(event.event_end_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	// console.log('start_date', event.start_date, start_date);
	// console.log('end_date', event.end_date, end_date);

	let date_display = '-- unknown date --';
	if (end_date === null) { // no end date
		date_display = start_date.format('MMM D h:mma');
	} else if (start_date.format('YYYY-MM-DD HH:mm') === end_date.format('YYYY-MM-DD HH:mm')) { // same start and end date/time
		date_display = start_date.format('MMM D h:mma');
	} else if (start_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) { // same start and end date, different time
		date_display = start_date.format('MMM D h:mma') + ' - ' + end_date.format('h:mma');
	} else { // different start and end dates
		date_display = start_date.format('MMM D') + ' - ' + end_date.format('MMM D');
	}

	return date_display;
}

export const timeDisplay = event => {

	// console.log(event);
	let end_date = '';
	let end_date_moment = null
	if ('event_end_date' in event && event.event_end_date.length>0) {
		end_date_moment = moment.tz(event.event_end_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
		end_date = end_date_moment.format(end_date_moment.format('mm')==='00' ? 'h a' : 'h:mm a').replace('am', 'a.m.').replace('pm', 'p.m.');
	}

	const start_date_moment = moment.tz(event.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
	let start_date = start_date_moment.format('mm')==='00' ? start_date_moment.format('h') : start_date_moment.format('h:mm');
	if (!end_date || end_date_moment===null || start_date_moment.format('a') !== end_date_moment.format('a'))
		start_date += start_date_moment.format(' a').replace('am', 'a.m.').replace('pm', 'p.m.');

	let date_display = start_date;
	if (end_date)
		date_display += ' - ' + end_date;

	return date_display;
}