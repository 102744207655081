import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import { Box, Button, Heading } from 'grommet';
import { Checkmark as CheckmarkIcon, Configure as ConfigureIcon } from 'grommet-icons';

class AdminChoise extends Component {

  render() {

    return (
      <Box className="AdminChoice" fill flex elevation='small' align='center' justify='center'>
        <Heading level="2">
          What would you like to do?
        </Heading>
        <Box direction="row-responsive">
          <Box>
            <Link to="/checkin/confirm">
              <Button
                icon={<CheckmarkIcon />}
                label="Check In"
                margin="small"
              />
            </Link>
          </Box>
          <Box>
            <Link to="/checkin/configure">
              <Button
                icon={<ConfigureIcon />}
                label="Configure"
                margin="small"
                primary={true}
                color="#3c5267"
              />
            </Link>
          </Box>
        </Box>
      </Box> 
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    load: { statusMap },
  } = state;
  
  return {
    contact,
    contactId,
    mobileMode,
    statusMap,
  };
}

const mapDispatchToProps = dispatch => {

  const handleLogout = () => dispatch(logout());
  const handleMessage = (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout));

  return {
    handleLogout,
    handleMessage,
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminChoise);
