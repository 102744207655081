import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Image, Menu } from 'grommet';
import logo from 'assets/images/logohoz-white.png';

const modes = [
  {
    label: 'Configure',
    to: '/checkin/configure',
  },
  {
    label: 'Check In',
    to: '/checkin/confirm',
  },
];

class AppBar extends Component {

  render() {

    const { accessCivicrm, history, loggedIn, testMode } = this.props;
    const { location: { pathname }} = history;

    return (
      <div className="AppBar">
        <Box
          tag='header'
          direction='row'
          align='center'
          justify='between'
          background={ testMode ? "#000" : "brand" }
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
          elevation='medium'
          {...this.props}
        >
          <Image
            className="logo"
            alignSelf="start"
            margin="none"
            src={logo}
          />
          {loggedIn && accessCivicrm && ['/checkin/configure','/checkin/confirm'].includes(pathname) ?
            <Menu
              label="Mode"
              size="medium"
              items={modes.map(mode => {
                const active = pathname===mode.to;
                return {
                  label: <span style={active ? { fontWeight:'bold', textDecoration: 'underline', } : {}}>{mode.label}</span>,
                  onClick: () => !active && history.push(mode.to),
                };
              })}
            />
            : null
          }
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { accessCivicrm, loggedIn, testMode } = state.auth;
  return {
    accessCivicrm,
    loggedIn,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AppBar);