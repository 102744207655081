import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Heading } from 'grommet';
import { timeDisplay } from 'helpers/CiviCRM';
import * as moment from 'moment-timezone';
import './Auth/styles.css';

import Barcode from './Auth/Barcode';
import UsernamePassword from './Auth/UsernamePassword';

class Login extends Component {

  render() {

    const { loaded, formValues } = this.props;

    const now = moment().tz("America/Los_Angeles");
    let event, start_date;

    if (loaded && 'event' in formValues && formValues.event) {
      event = formValues.event;
      start_date = moment.tz(event.event_start_date, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles");
    }
    
    return (
      <Box className="Login" direction="row-responsive" wrap={true} fill={true}>
        <Box pad="medium" basis="1/4" className="welcome" justify="start">
          <Heading level="1" margin={{ top: "none", left: "none", right: "none", bottom: "medium", }}>
            Welcome to Senior Services
          </Heading>
          <Heading level="2" margin="none">
            New to the Center or having trouble logging in?
            <span style={{ fontSize: '0.65em', paddingLeft: '20px' }}>Please see desk attendent.</span>
          </Heading>
        </Box>
        <Box className="actions" flex basis="3/4" justify="start" pad={{ top: "large", bottom: "none", left: "large", right: "large" }}>
          <Box className="new" pad="medium" round="small">
            {event ? (
              start_date.isAfter(now) ?
                // event in future
                <Heading level="2" margin="none">
                  Check into {event.event_title} at {timeDisplay(event)}
                </Heading>
              :
                // event in past
                <Heading level="2" margin="none">
                  Check into {event.event_title} at {timeDisplay(event)} (event has started)
                </Heading>
            ) : (
              <Heading level="2" margin="none" color="status-error">
                No event configured
              </Heading>
            )}
          </Box>
          <Box pad="none">
            <Box className="auth-options" flex direction='row-responsive' justify="center">
              <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
                <Box className="auth-option" pad="small" round="small">
                  <Heading margin={{ top: "small", bottom: "small", left: "none", right: "none" }}>
                    Login
                  </Heading>
                  <Heading level="4" margin={{ top: "small", bottom: "medium", left: "none", right: "none" }}>
                    Use your username and password you signed up with through the portal.
                  </Heading>
                  <UsernamePassword />
                </Box>
              </Box>
              <Box pad="medium" basis="1">
                <Box className="or-box" direction="column" justify="center" align="center" pad={{ vertical: "small" }}>
                  <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
                  <Box pad={{ vertical: "medium" }} >OR</Box>
                  <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
                </Box>
              </Box>
              <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
                <Box className="auth-option" pad="small" round="small">
                  <Heading margin={{ top: "small", left: "none", right: "none", bottom: "medium" }}>
                    Scan ID Card
                  </Heading>
                  <Barcode />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const formName = 'configure';

const mapStateToProps = (state) => {

  const {
    display: { mobileMode },
    // single: { event },
    form,
  } = state;

  const loaded = formName in form && 'values' in form[formName];
  
  return {
    mobileMode,
    // event,
    loaded,
    formValues: loaded && form[formName].values,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
