// https://github.com/grommet/grommet/blob/master/src/js/themes/base.js
import { defaultProps } from 'grommet';
import { rgba } from 'polished';

const brandColor = '#7D4CDB';
const accentColors = ['#6FFFB0', '#FD6FFF', '#81FCED', '#FFCA58'];
const neutralColors = ['#00873D', '#3D138D', '#00739D', '#A2423D'];
const statusColors = {
  critical: '#FF4040',
  error: '#FF4040',
  warning: '#FFAA15',
  ok: '#00C781',
  unknown: '#CCCCCC',
  disabled: '#CCCCCC',
};
const darkColors = [
  '#333333',
  '#555555',
  '#777777',
  '#999999',
  '#999999',
  '#999999',
];
const lightColors = [
  '#F8F8F8',
  '#F2F2F2',
  '#efecec',
  '#DADADA',
  '#DADADA',
  '#DADADA',
];
const focusColor = accentColors[0];

const colors = {
  active: rgba(221, 221, 221, 0.5),
  black: '#000000',
  border: {
    dark: rgba(255, 255, 255, 0.33),
    light: rgba(0, 0, 0, 0.33),
  },
  brand: brandColor,
  control: {
    dark: 'accent-1',
    light: 'brand',
  },
  focus: focusColor,
  placeholder: '#AAAAAA',
  selected: 'brand',
  text: {
    dark: '#f8f8f8',
    light: '#444444',
  },
  icon: {
    dark: '#f8f8f8',
    light: '#666666',
  },
  white: '#FFFFFF',
};

const colorArray = (array, prefix) =>
  array.forEach((color, index) => {
    colors[`${prefix}-${index + 1}`] = color;
});

colorArray(accentColors, 'accent');
colorArray(darkColors, 'dark');
colorArray(lightColors, 'light');
colorArray(neutralColors, 'neutral');
Object.keys(statusColors).forEach(color => {
  colors[`status-${color}`] = statusColors[color];
});

// its read only, need to make it readable
const theme = JSON.parse(JSON.stringify(defaultProps.theme));

// brand
theme.global.colors.brand = '#888';

// font props
// theme.global.font.family = 'TradeGothic';
theme.global.font.family = 'sans-serif';
theme.global.font.size = '32px';
theme.global.font.height = '36px';

// input props
theme.checkBox.size = '72px';
theme.radioButton.size = '72px';
theme.radioButton.icon.size = '56px';

// colors
theme.colors = colors;

// buttons
theme.button.border.color = '#919295';
// theme.button.primary.color = '#3c5267';
theme.button.padding.vertical = '12px';
theme.button.padding.horizontal = '55px';
theme.button.extend = {
  fontSize: '32px',
  lineHeight: '32px',
};

// images
theme.image = {
  extend: {
    maxWidth: '100%',
    height: 'auto',
  }
};

theme.menu.extend = {
  fontSize: '24px',
};

// const theme = {
//   global: {
//     colors: {
//       brand: '#888',
//     },
//     font: {
//       // family: 'TradeGothic',
//       family: 'sans-serif',
//       size: '32px',
//       height: '36px',
//     },
//   },
//   checkBox: {
//     size: '72px',
//   },
//   radioButton: {
//     size: '72px',
//   },
//   colors,
//   button: {
//     border: {
//       color: '#919295',
//     },
//     primary: {
//       color: '#3c5267',
//     },
//     padding: {
//       vertical: '12px',
//       horizontal: '55px',
//     },
//     extend: {
//       // color: '#FFF',
//       // background: '#4d95de',
//       fontSize: '32px',
//       lineHeight: '32px',
//     },
//   },
// };

export default theme;