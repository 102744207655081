// import constants from './constants';
// import * as moment from 'moment-timezone';
import { cloneDeep } from 'lodash-es';

// console.log(window.location.hostname, window.location.hostname.match(process.env.REACT_APP_DEV_HOSTNAME))

export const defaultState = {
  auth: {
    isFetching: false,
    testMode: process.env.NODE_ENV === "development" || window.location.hostname.match(process.env.REACT_APP_DEV_HOSTNAME)!==null,
    loggedIn: false,
    contactId: null,
    accessCivicrm: null,
    contact: null,
    apiKey: '',
    defaultApiKey: process.env.REACT_APP_DEFAULT_API_KEY, // this is the api key to very unprivliged user
    loggedInApiKey: null,
    siteKey: process.env.REACT_APP_SITE_KEY,
    siteURL: '',
    siteURLDev: process.env.REACT_APP_DEV_URL,
    siteURLLive: process.env.REACT_APP_LIVE_URL,
  },
  display: {
    width: 0,
    height: 0,
    mobileMode: true,
  },
  load: {
    setup: false,
    statusMap: {},
  },
  list: {
    events: {
      data: null,
      count: null,
      sortBy: [
        { columnName: 'event_start_date', direction: 'asc' }
      ],
      searchValue: '',
      currentPage: 0,
      pageSize: 0,
      tableState: '',
      loadingTableState: '',
    },
    participations: {
      data: null,
      count: null,
      sortBy: [
        { columnName: 'event_start_date', direction: 'asc' }
      ],
      searchValue: '',
      currentPage: 0,
      pageSize: 0,
      tableState: '',
      loadingTableState: '',
    },
  },
  single: {
    event: {
      isFetching: false,
      data: null,
      state: '',
      loadingState: '',
    },
  },
  messages: {
    items: [
      // {
      //   id: 'asdf',
      //   message: 'test',
      //   variant: 'success',
      //   logout: false,
      // },
    ],
  },
}

let initialState = cloneDeep(defaultState);

// const storedStateJSON = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
// if (storedStateJSON!==null) {
//   try {

//     const storedState = JSON.parse(storedStateJSON);
//     initialState = Object.assign({}, initialState, storedState);

//   } catch (e) {
//     // wasn't json i guess, no worries
//   }
// }

export default initialState;