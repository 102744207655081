import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize, reset } from 'redux-form';
import { authSetup } from 'store/actions/auth';
import { windowResize } from 'store/actions/display';
import { loadStatusMap } from 'store/actions/load';
import { Box, Grommet } from 'grommet';
import cx from 'classnames';
import theme from 'theme';
import './App/styles.css';
import * as moment from 'moment-timezone';

import AppBar from './App/AppBar';
import Footer from './App/Footer';
import LoadingOverlay from './App/LoadingOverlay';
import Main from './Main';
import Messages from './App/Messages';
import Auth from './Auth';
import NotFound from './NotFound';

let prevCurrentDay = moment().tz("America/Los_Angeles").dayOfYear();
let clearConfigureInterval = null;

class App extends Component {

  componentDidMount() {

    const { handleAuthSetup, handleInitialize, handleLoadStatusMap } = this.props;
    
    handleAuthSetup();
    handleLoadStatusMap();
    this.resize();

    // setup a interval to check once per minute if the day had changed
    clearConfigureInterval = setInterval(() => {
      let currentDay = moment().tz("America/Los_Angeles").dayOfYear();
      if (currentDay === prevCurrentDay)
        return;
      prevCurrentDay = currentDay;
      handleInitialize('configure', {});
    }, 30000);

    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {

    // clear the clear configuration interval if it exists
    if (clearConfigureInterval!==null)
      clearInterval(clearConfigureInterval);

    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    const
      w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
      height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    this.props.handleWindowResize(width,height);
  }  
  
  render() {

    const { loading, loggedIn, testMode } = this.props;

    return (
      <Grommet full theme={theme} className={cx({ App: true, testMode })}>
        <Box fill>
          <Route path="*" component={AppBar} />
          {loggedIn ? (
            <Switch>
              <Route exact path='/' component={props => <Redirect to={{ pathname: "/checkin", state: { from: props.location } }} />} />
              <Route path="/checkin" component={Main} />
              <Route exact path='/login' component={props => <Redirect to={{ pathname: "/", state: { from: props.location } }} />} />
              <Route component={NotFound} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path='/' component={props => <Redirect to={{ pathname: "/login", state: { from: props.location } }} />} />
              <Route exact path='/login' component={Auth} />
              <Route path="/checkin" component={props => <Redirect to={{ pathname: "/", state: { from: props.location } }} />} />
              <Route component={NotFound} />
            </Switch>
          )}
          <Footer />
          <Messages />
        </Box>
        {loading ? <LoadingOverlay /> : null}
      </Grommet>
    );
  }
}


const mapStateToProps = (state) => {
  const { accessCivicrm, contact, isFetching, loggedIn, testMode } = state.auth;
  const { setup } = state.load;
  // console.log({ isFetching, setup, loading: isFetching || !setup})
  return {
    accessCivicrm,
    contact,
    loading: isFetching || !setup, // show our loading overlay
    isFetching,
    loggedIn,
    setup,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleAuthSetup: () => dispatch(authSetup()),
    handleLoadStatusMap: () => dispatch(loadStatusMap()),
    handleWindowResize: (width,height) => dispatch(windowResize(width,height)),
    handleInitialize: (formName, data) => dispatch(initialize(formName, data)),
    handleReset: formName => dispatch(reset(formName)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
