import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import { Box } from 'grommet';
import './Main/styles.css';
import StillHere from './Main/StillHere';
import WelcomeHeading from './Main/WelcomeHeading';

import AdminChoice from './AdminChoice';
import Checkin from './Checkin';
import Configure from './Configure';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

let stillHereTimeout = null;

class Main extends Component {

  constructor(props){
    super(props);
    this.state = {
      stillHereOpen: false,
    };
  }

  componentDidMount() {

    // setup events to trigger still here
    this.setupStillHereTimeout();
    const handler = e => this.setupStillHereTimeout();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentWillUnmount() {

    // clear still here timeout if it still exists
    if (stillHereTimeout!==null)
      clearTimeout(stillHereTimeout);

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true));
  }

  setupStillHereTimeout = () => {

    // clear still here timeout if it exists
    if (stillHereTimeout!==null)
      clearTimeout(stillHereTimeout);

    
    stillHereTimeout = setTimeout(() => this.toggleStillHere(), 60000); // 60 seconds
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen)
      this.setupStillHereTimeout();

    this.setState({ stillHereOpen });
  }

  render() {

    const { accessCivicrm } = this.props;
    const { stillHereOpen } = this.state;

    return (
      <Box className="Main" direction='column' fill flex elevation='small'>
        <div>
          <Route path="*" component={WelcomeHeading} />
        </div>
        <Box fill className="mainArea">
          { accessCivicrm ?
            // admin paths
            <Switch>
              <Route exact path="/checkin/confirm" component={Checkin} />
              <Route exact path="/checkin/choose"  component={AdminChoice} />
              <Route exact path="/checkin/configure"  component={Configure} />
              <Route path="/checkin" component={props => <Redirect to={{ pathname: "/checkin/choose", state: { from: props.location } }} />} />
            </Switch>
            :
            // regular user paths
            <Switch>
              <Route exact path="/checkin/confirm" component={Checkin} />
              <Route path="/checkin" component={props => <Redirect to={{ pathname: "/checkin/confirm", state: { from: props.location } }} />} />
            </Switch>
          }
        </Box>
        <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    auth: { accessCivicrm, contact, contactId },
    display: { mobileMode },
    load: { statusMap },
  } = state;
  
  return {
    accessCivicrm,
    contact,
    contactId,
    mobileMode,
    statusMap,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleLogout: () => dispatch(logout()),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Main);
