import React from 'react';
import { Image } from 'grommet';

import checkin_confirm from 'assets/images/help/checkin_confirm.png';
import checkin_msg from 'assets/images/help/checkin_msg.png';
import all_done from 'assets/images/help/all_done.png';
import scan_id_card from 'assets/images/help/scan_id_card.png';
import username_password from 'assets/images/help/username_password.png';

const Help = props => (
  <div className="Help">
    <p><strong>Q: How do I login to the Kiosk?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      There are two ways to login to the Kiosk.
    </p>
    <ol>
      <li>
        Scan or swipe the QR Code that's on your key tag on the webcam.
        <br />
        <Image src={scan_id_card} margin="small" />
      </li>
      <li>
        Tap the space under Username and Password. An on-screen keyboard will appear on the bottom right corner of 
        the screen. Type in your Username and Password that you've signed up with through the Portal and then click 
        the Login button.
        <br />
        <Image src={username_password} margin="small" />
      </li>
    </ol>
    <p><strong>Q: What if I lose my key tag or forget my login information or both?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Please contact a Senior Services staff member for a temporary replacement key tag or to provide you with your 
      username and password.
    </p>
    <p><strong>Q: I'm new to the Center. How can I check-in for an event?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Please see a Senior Services staff member at the desk to request further information on how to be a registered 
      client of the Center.
    </p>
    <p><strong>Q: How do I confirm to attend an event?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Once you are logged into the Room Kiosk, please tap on 'Confirm' under the message that says 'You will 
      be checked in to “NAME OF EVENT” as shown below.
      <br />
      <Image src={checkin_msg} margin="small" />
    </p>
    <p><strong>Q: Can I cancel my confirmation after I checked into an event?</strong></p>
    <p>
      <strong><em>A: </em></strong>
      Yes you can cancel after you have already confirmed that you'll be attending an event. Please tap your 
      ID card on the Kiosk again and it will show you a warning that you will be checked out of the event if 
      you tap on 'Confirm' as shown below. But if you don't wish to cancel your confirmation, please tap on 
      'Cancel' or 'I'm Done'.
      <br />
      <Image src={checkin_confirm} margin="small" />
    </p>
    <p><strong>Q: How do I log out of the Kiosk?</strong></p>
    <p>
      Once you're ready to log out of the Kiosk, please tap on the 'I'm Done' button on the top right corner of the screen as shown here.
      <br />
      <Image src={all_done} margin="small" />
    </p>

  </div>
);

export default Help;