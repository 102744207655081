import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
  updateListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box, Button, Heading } from 'grommet';
import { Checkmark as CheckmarkIcon, /*Clear as ClearIcon*/ } from 'grommet-icons';
import * as moment from 'moment-timezone';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { registrationStatus } from 'helpers/CiviCRM';
import locationsList from 'helpers/locations';
import roomsList from 'helpers/rooms';
import './Configure/styles.css';

import EventDisplay from './Configure/EventDisplay';

class Configure extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: props.data===null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {
    // clear state to prevent event flash on reload
    this.props.updateCurrentListState('');
    this.props.setListData({ data: null });
  }

  calculateCurrentTableState = () => {
    const tableState = [
      this.props.contactId,
      this.props.searchValue,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      // this.props.count === null? 'null' : this.props.count,
      this.props.pageSize,
      this.props.currentPage,
    ].join('|');
    return tableState;
  }

  loadData = async () => {
    
    const currentTableState = this.calculateCurrentTableState();
    const { tableState, loadingTableState } = this.props;
    // console.log({ currentTableState, tableState, loadingTableState})
    if (
      currentTableState === tableState ||
      currentTableState === loadingTableState
    ) return;

    this.props.updateLoadingListState(currentTableState);
    this.setState({ loading: true });

    const start = moment().tz('America/Los_Angeles').startOf('day');
    const end = moment().tz('America/Los_Angeles').endOf('day');
    // console.log({ start, end })

    const returnFields = [
      "event_title",
      "summary",
      "description",
      "event_type_id",
      "event_type",
      "event_start_date",
      "event_end_date",
      "is_online_registration",
      "is_full",
      "available_seats",
      "max_participants",
      "custom_1198", // Event Location
      "custom_1205", // Other Event Location
      "custom_1206", // Other Event Location Address
      "custom_1246", // Drop In Allowed
      "custom_1248", // closed group
      "custom_1305", // Room / Location
    ];

    const url = handleCiviURLBuild('PublicEvent','get',{
      sequential:1,
      start_date: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      },
      // end_date: {
      //   BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      // },
      return: returnFields,
      // 'api.Participant.get': {
      //   sequential: 1,
      //   event_id: "$value.id",
      //   contact_id: "user_contact_id",
      // },
      options:{
        // or :[
        //   ["start_date","end_date"]
        // ],
        limit :0,
      },
    });
    // console.log({ url })
    
    try {
      
      const response = await fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      const json = await response.json();
      if (json.is_error)
        throw new Error(json.error_message);

      const data = json.values.map(event => {
        // civicrm is inconsistant in returning variables
        returnFields.forEach(field => {
          if (!(field in event))
            event[field] = '';
        });
        const { custom_1198, custom_1205, custom_1206, custom_1305 } = event;
        const eventLocation = locationsList.find(location => location.value === custom_1198);
        const roomLocation = roomsList.find(room => room.value === custom_1305);
        event.checkinStatus = registrationStatus(event); // add in our statuses
        event.event_location_value = eventLocation ? eventLocation.value : null;
        event.event_location = eventLocation && eventLocation !== 'Other' ?
          eventLocation.label :
          (custom_1205.length > 0 ? custom_1205 : '');
        event.event_location_address = custom_1206;
        // room number
        event.room_location_value = roomLocation ? roomLocation.value : null;
        event.room_location = roomLocation ? roomLocation.label : null;
        return event;
      });

      // update list data
      this.props.updateCurrentListState(currentTableState);
      this.props.setListData({ data });
      this.props.updateLoadingListState('');
      
      // done loading
      this.setState({ loading: false });
    }
    catch (e) {
      this.setState({ loading: false });
      this.props.handleMessage(e.message, 'error');
    }
    
  }

  render() {

    const { data, handleLogout, handleSubmit } = this.props;
    const position = data===null || data.length===0 ? 'center' : 'start';

    return (
      <Box
        className="Configure"
        fill
        flex
        align={position}
        justify={position}
      >
        {data==null ? // nothing loaded from server yet
          <Box>Loading...</Box>
        : (
          data.length===0 ? ( // no events today
            <Box>No events today</Box> 
          ) : ( // we have event we can setup for checkin
            <Box fill pad="medium">
              <Form onSubmit={handleSubmit}>
                <Heading level="1" textAlign="center">
                  Which event should clients be checked in to?
                </Heading>
                <Box direction="row-responsive" wrap={true}>
                  {data.map(event => 
                    <Box key={event.id} basis="calc(33% - 18px)" margin="small">
                      <EventDisplay event={event} />
                    </Box>
                  )
                  //.reduce((prev, curr, i) => i>0 ? [prev, <hr key={'br-'+curr.id} />, curr] : curr, '')
                  }
                </Box>
                <Box direction="row-responsive" justify="center">
                  {/* <Box>
                    <Button
                      icon={<ClearIcon />}
                      label="Cancel"
                      margin="small"
                    />
                  </Box> */}
                  <Box>
                    <Button
                      icon={<CheckmarkIcon />}
                      label="I'm Done"
                      margin="small"
                      primary={true}
                      color="#3c5267"
                      onClick={() => handleLogout()}
                    />
                  </Box>
                </Box>
              </Form>
            </Box>
          )
        )}
      </Box>
    );
  }
}

const listName = 'events';
const formName = 'configure';

const validate = vals => {
  const errors = {};

  if (
    (!('checkin' in vals) || Object.keys(vals.checkin).length<1) &&
    (!('registrations' in vals) || Object.keys(vals.registrations).length<1) 
  ) {
    errors.checkin = 'At least one check in or registration must be selected';
  }

  return errors;
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    load: { statusMap },
  } = state;

  const loaded = typeof state.form[formName] !== 'undefined' && 'values' in state.form[formName];
  
  return {
    contact,
    contactId,
    mobileMode,
    statusMap,

    loaded,
    formValues: loaded ? state.form[formName].values : null,

    ...state.list[listName],
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleLogout: () => dispatch(logout()),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    onSubmit: data => null,
    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: tableState => dispatch(updateCurrentListState(listName, tableState)),
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
    updateListData: updates => dispatch(updateListData(listName, updates, (current, update) => {
      // console.log(current.id, update.id, current.id===update.id);
      return current.id===update.id
    }, true)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    destroyOnUnmount: false,
    validate,
  })(Configure)
);
