import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import {
  updateCurrentPage, 
  updatePageSize, 
  updateSorting, 
  updateSearchValue, 
  updateCurrentListState,
  updateLoadingListState,
  setListData,
  updateListData,
} from 'store/actions/list';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box, Button, Heading } from 'grommet';
import { Checkmark as CheckmarkIcon, Clear as ClearIcon } from 'grommet-icons';
import * as moment from 'moment-timezone';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import { participationStatus, registrationStatus } from 'helpers/CiviCRM';
import beep from 'assets/audio/beep';
import locationsList from 'helpers/locations';
import roomsList from 'helpers/rooms';

class Checkin extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: props.data===null,
    };
  }

  componentDidMount() {
    this.loadData()
    .then(() => this.setupForm());
  }

  componentDidUpdate() {
    this.loadData()
    .then(() => this.setupForm());
  }

  componentWillUnmount() {
     // clear state to prevent event flash on reload
    this.props.updateCurrentListState('');
    this.props.setListData({ data: null });
  }

  calculateCurrentTableState = () => {
    const tableState = [
      this.props.contactId,
      this.props.searchValue,
      this.props.sortBy.map(value => value.columnName + ' ' + value.direction).join(','),
      // this.props.count === null? 'null' : this.props.count,
      this.props.pageSize,
      this.props.currentPage,
    ].join('|');
    return tableState;
  }

  loadData = async () => {
    
    const currentTableState = this.calculateCurrentTableState();
    const { tableState, loadingTableState } = this.props;
    // console.log({ currentTableState, tableState, loadingTableState})
    if (
      currentTableState === tableState ||
      currentTableState === loadingTableState
    ) return;

    this.props.updateLoadingListState(currentTableState);
    this.setState({ loading: true });

    const { statusMap } = this.props;
    // console.log(contact);

    const start = moment().tz('America/Los_Angeles').startOf('day');
    const end = moment().tz('America/Los_Angeles').endOf('day');
    // console.log({ start, end })

    const returnFields = [
      "event_title",
      "summary",
      "description",
      "event_type_id",
      "event_type",
      "event_start_date",
      "event_end_date",
      "is_online_registration",
      "is_full",
      "available_seats",
      "max_participants",
      "custom_1198", // Event Location
      "custom_1205", // Other Event Location
      "custom_1206", // Other Event Location Address
      "custom_1246", // Drop In Allowed
      "custom_1248", // closed group
      "custom_1305", // Room / Location
    ];

    const url = handleCiviURLBuild('PublicEvent','get',{
      sequential:1,
      start_date: {
        BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      },
      // end_date: {
      //   BETWEEN: [start.format('YYYY-MM-DD HH:mm:ss'),end.format('YYYY-MM-DD HH:mm:ss')]
      // },
      return: returnFields,
      'api.Participant.get': {
        sequential: 1,
        event_id: "$value.id",
        contact_id: "user_contact_id",
      },
      options:{
        // or :[
        //   ["start_date","end_date"]
        // ],
        limit :0,
      },
    });
    // console.log({ url })
    
    try {

      const response = await fetch(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });
      const json = await response.json();
      if (json.is_error)
        throw new Error(json.error_message);

      const data = json.values.map(event => {
        // civicrm is inconsistant in returning variables
        returnFields.forEach(field => {
          if (!(field in event))
            event[field] = '';
        });
        const { custom_1198, custom_1205, custom_1206, custom_1305 } = event;
        const eventLocation = locationsList.find(location => location.value === custom_1198);
        const roomLocation = roomsList.find(room => room.value === custom_1305);
        event.checkinStatus = registrationStatus(event); // add in our statuses
        event.event_location_value = eventLocation ? eventLocation.value : null;
        event.event_location = eventLocation && eventLocation !== 'Other' ?
          eventLocation.label :
          (custom_1205.length > 0 ? custom_1205 : '');
        event.event_location_address = custom_1206;
        // room number
        event.room_location_value = roomLocation ? roomLocation.value : null;
        event.room_location = roomLocation ? roomLocation.label : null;
        event['api.Participant.get'].values =
          event['api.Participant.get'].values
            .map(p => {
              // copy event stuff to participation
              returnFields.forEach(field => {
                p[field] = event[field];
              });
              p.checkinStatus = participationStatus(p, statusMap);
              p.event_location_value = event.event_location_value;
              p.event_location = event.event_location;
              p.event_location_address = event.event_location_address;
              p.room_location_value = event.room_location_value;
              p.room_location = event.room_location;
              return p;
            });
        return event;
      });

      // console.log(data);
      // update list data
      this.props.updateCurrentListState(currentTableState);
      this.props.setListData({ data });
      this.props.updateLoadingListState('');
      
      // update form data
      const formData = {
        checkins: {},
        registrations: {},
      };
      
      // we are checked if we already checked in, dont initalize all because it will change statuses unnecessarily
      data.forEach(event => {
        event['api.Participant.get'].values.forEach(participation => {
          if (participation.checkinStatus === "attended") {
            formData.checkins["checkin_" + participation.id] = true;
          }
        });
      });
      this.props.initialize(formData);
      
      // done loading
      this.setState({ loading: false });
    }
    catch (e) {
      this.setState({ loading: false });
      this.props.handleMessage(e.message, 'error');
    }
    
  }

  determineConfigureStatus = () => {

    const {
      configureLoaded,
      configureFormValues,
      data, // list of events from today
    } = this.props;

    // gather up what event this kiosk is configured for
    const configuredEvent = configureLoaded && 'event' in configureFormValues && configureFormValues.event ?
      configureFormValues.event
      : null;

    // find the specific event in the loaded data
    const event = configuredEvent && data!==null ?
      data.find(e => e.id === configuredEvent.id)
      : null;

    // no event is configured for this kiosk
    if (!configuredEvent)
      return 'not-configured';

    // we havent loaded the list of todays events, need to know if we are already registered
    if (data===null)
      return 'loading';

    // there arent any events today?!?!?
    if (data.length===0)
      return 'no-data';

    // the event we are configured for does not exist in the loaded list
    if (!event)
      return 'does-not-exist';

    // we definitely have an event with our information

    // not currently registered, but we can drop in
    if (event.checkinStatus==='dropin')
      return 'dropin';

    // not the correct check in status to be able to attend this event
    // only looking for something we are already registered
    if (!['registereddropin', 'registered'].includes(event.checkinStatus))
      return 'wrong-check-in-status';

    // at this point we should be known to be registered
    if (event['api.Participant.get'].values.length===0)
      return 'error';

    // we are just going to assume 1 participation
    // lets check our participation status
    const participation = event['api.Participant.get'].values[0];

    // we are already set to attened
    if (['attended'].includes(participation.checkinStatus))
      return 'attended';

    if (['registered', 'pendingattended'].includes(participation.checkinStatus))
      return 'not-attended';
    
    return 'wrong-participation-status';
  }

  setupForm = () => {

    const status = this.determineConfigureStatus();

    if (!['dropin','attended','not-attended'].includes(status))
      return;

    // hmmmm
    const {
      configureLoaded,
      configureFormValues,
      data, // list of events from today
      initialize,
    } = this.props;
    // console.log(data);

    // gather up what event this kiosk is configured for
    const configuredEvent = configureLoaded && 'event' in configureFormValues && configureFormValues.event ?
      configureFormValues.event
      : null;

    // find the specific event in the loaded data
    const event = configuredEvent && data!==null ?
      data.find(e => e.id === configuredEvent.id)
      : null;
    // console.log(event);

    const formData = {
      checkins: {},
      registrations: {},
    };

    if (status==='dropin') {

      formData.registrations[`register_${event.id}`] = true;

    } else {

      const participation = event['api.Participant.get'].values[0];

      if (status==='attended')
        formData.checkins[`checkin_${participation.id}`] = false;

      if (status==='not-attended')
        formData.checkins[`checkin_${participation.id}`] = true;
    }

    initialize(formData);
  }

  render() {

    const { props } = this;

    const { 
      configureFormValues,
      configureLoaded,
      data, // list of events from today
      handleLogout,
      handleSubmit,
    } = props;

    const status = this.determineConfigureStatus();

    let event = null;
    if ([
      'dropin',
      'attended',
      'not-attended',
      'wrong-check-in-status',
      'wrong-participation-status',
    ].includes(status)) {
      // gather up what event this kiosk is configured for
      const configuredEvent = configureLoaded && 'event' in configureFormValues && configureFormValues.event ?
        configureFormValues.event
        : null;

      // find the specific event in the loaded data
      event = configuredEvent && data!==null ?
        data.find(e => e.id === configuredEvent.id)
        : null;
      // console.log(event);
    }
    // console.log(status);

    return (
      <Box className="Checkin" fill flex elevation='small' align='center' justify='center'>
        {[
          'not-configured',
          'loading',
          'no-data',
          'does-not-exist',
          'wrong-check-in-status',
          'wrong-participation-status',
          'error',
        ].includes(status) ?
          <Box pad="medium">
            <Heading level="1" textAlign="center">
              {{
                'not-configured':
                  'No event is configured for this kiosk. Please see a staff member',
                'loading':
                  'Loading...',
                'no-data':
                  'No events today',
                'does-not-exist':
                  'Configured event does not exist',
                'wrong-check-in-status':
                  'You are not allowed to check into ' + (event ? '"'+event.event_title+'"' : 'this event') + '. Please see the desk.',
                'wrong-participation-status':
                  'You are not allowed to check into ' + (event ? '"'+event.event_title+'"' : 'this event') + '. Please see the desk.',
                'error':
                  'There was an error. Please see the desk.',
              }[status]}
            </Heading>
          </Box>
        :
          <Form onSubmit={handleSubmit}>
            <Heading level="1" textAlign="center">
              {/* {status} */}
              {['dropin','not-attended'].includes(status) ? ( // existing registration or drop in event
                <>You will be checked in to "{event.event_title}"</>
              ) : ( // already marked as attened, check out of event
                <>WARNING! You are already check in to "{event.event_title}". By continuing you will be checked out of "{event.event_title}".</>
              )}
            </Heading>
            <Box direction="row-responsive" justify="center">
              <Box>
                <Button
                  icon={<ClearIcon />}
                  label="Cancel"
                  margin="small"
                  onClick={() => handleLogout()}
                />
              </Box>
              <Box>
                <Button
                  icon={<CheckmarkIcon />}
                  label="Confirm"
                  type="submit"
                  margin="small"
                  primary={true}
                  color="#3c5267"
                />
              </Box>
            </Box>
          </Form>
        }
      </Box>
    );
  }
}

const listName = 'participations';
const formName = 'checkin';
const configureFormName = 'configure';

const validate = vals => {
  const errors = {};

  if (
    (!('checkin' in vals) || Object.keys(vals.checkin).length<1) &&
    (!('registrations' in vals) || Object.keys(vals.registrations).length<1) 
  ) {
    errors.checkin = 'At least one check in or registration must be selected';
  }

  return errors;
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    form,
    load: { statusMap },
  } = state;

  const loaded = typeof form[formName] !== 'undefined' && 'values' in form[formName];
  const configureLoaded = typeof form[configureFormName] !== 'undefined' && 'values' in form[configureFormName];
  
  return {
    contact,
    contactId,
    mobileMode,
    statusMap,

    loaded,
    configureLoaded,

    formValues: loaded ? state.form[formName].values : null,
    configureFormValues: configureLoaded ? state.form[configureFormName].values : null,

    ...state.list[listName],
  };
}

const mapDispatchToProps = dispatch => {

  const handleLogout = () => dispatch(logout());
  const handleMessage = (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout));
  const handleupdateCurrentListState = tableState => dispatch(updateCurrentListState(listName, tableState));
  const handleUpdateListData = updates => dispatch(updateListData(listName, updates, (current, update) => {
    // console.log(current.id, update.id, current.id===update.id);
    return current.id===update.id
  }, true));

  return {
    handleLogout,
    handleMessage,

    onSubmit: (data) => {

      const requests = [];

      // Log these failures
      if (!data) {
        console.log(data);
        fetch('https://crashes.frontcenter.org', {
          method: 'POST',
          headers: {
            Authorization: 'fee539ff-333f-455c-9bea-202059c66bf9'
          },
          body: JSON.stringify({data})
        }).catch(() => null);
        return;
      }
      
      Object.keys(data.checkins).forEach(key => {

        // console.log(data.checkins[key]);

        const parts = key.split('_');
        const participatnt_id = parts[1];
        const status_id = data.checkins[key] ? 2 : 1; // attended, registered

        const url = handleCiviURLBuild('Registration', 'create');
        const json = {
          sequential: 1,
          id: participatnt_id,
          status_id, 
        };

        const formData = new FormData();
        formData.append('json', JSON.stringify(json));
  
        const request = fetch(url, {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
        .then(response => response.json())
        .then(json => {
          if (json.is_error)
            throw new Error(json.error_message);
        })
        .catch((e) => {
          handleMessage(e.message, 'error');
        });

        requests.push(request);
      });

      Object.keys(data.registrations).forEach(key => {

        // console.log(data.registrations[key]);

        // not doing anything with this registration
        if (!data.registrations[key]) return;

        const parts = key.split('_');
        const event_id = parts[1];
        const status_id = 2; // attended

        const url = handleCiviURLBuild('Registration', 'create');
        const json = {
          sequential: 1,
          event_id,
          status_id, 
        };

        const formData = new FormData();
        formData.append('json', JSON.stringify(json));
  
        const request = fetch(url, {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: formData,
        })
        .then(response => response.json())
        .then(json => {
          if (json.is_error)
            throw new Error(json.error_message);
        })
        .catch((e) => {
          handleMessage(e.message, 'error');
        });

        requests.push(request);
      });

      Promise
      .all(requests)
      // .then(() => handleMessage('Check in statuses updated!', 'success', true))
      .finally(() => {
        // console.log("asdf")
        // handleupdateCurrentListState(uuid.v4()); // force reload data -- done with a logout action
        beep.play();
        handleLogout();
      });
    },

    updateCurrentPage: currentPage => dispatch(updateCurrentPage(listName, currentPage)),
    updatePageSize: pageSize => dispatch(updatePageSize(listName, pageSize)),
    updateSorting: sortBy => dispatch(updateSorting(listName, sortBy)),
    updateSearchValue: searchValue => dispatch(updateSearchValue(listName, searchValue)),
    updateCurrentListState: handleupdateCurrentListState,
    updateLoadingListState: (loadingTableState) => dispatch(updateLoadingListState(listName, loadingTableState)),
    setListData: data => dispatch(setListData(listName, data)),
    updateListData: handleUpdateListData,
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    validate,
  })(Checkin)
);
