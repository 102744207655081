import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { Box, Button, Heading, Layer } from 'grommet';
import { Clear, StatusGood } from 'grommet-icons';

class StillHere extends Component {

  constructor(props){
    super(props);
    this.state = {
      autoLogout: null,
    };
  }

  componentDidMount() {
    if (this.props.open) {
      this.setupStillHereTimeout();
    }
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (!prevProps.open && this.props.open) {
      this.setupStillHereTimeout();
    }
    if (prevProps.open && !this.props.open) {
      this.clearStillHereTimeout();
    }
  }

  componentWillUnmount() {
    this.clearStillHereTimeout();
  }

  clearStillHereTimeout = () => {

    if (this.state.autoLogout!==null) {
      clearTimeout(this.state.autoLogout);
    }
  }

  setupStillHereTimeout = () => {

    if (this.state.autoLogout!==null) {
      clearTimeout(this.state.autoLogout);
    }

    const autoLogout = setTimeout(() => this.props.handleLogout(), 30000); // 30 seconds
    this.setState({ autoLogout });
  }

  render() {

    const { contact, handleLogout, open, toggle } = this.props;

    if (!open)
      return null;

    return (
      <Layer
        className="StillHere"
        // full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>
                {contact===null ? 'Are you still here?' : contact.first_name + ', are you still here?'}
              </Heading>
            </Box>
          </Box>
          <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
            <Box pad="medium" margin="medium" justify="center" direction="row">
              <Box>
                <Button
                  icon={<Clear />}
                  margin="small"
                  label="No"
                  type="submit"
                  onClick={() => handleLogout()}
                />
              </Box>
              <Box>
                <Button
                  icon={<StatusGood />}
                  margin="small"
                  label="Yes"
                  type="submit"
                  primary={true}
                  color="#3c5267"
                  onClick={() => toggle(false)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth: { contact }} = state;
  return {
    contact,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(StillHere);