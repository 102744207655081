import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { Box, Button } from 'grommet';
import { Home } from 'grommet-icons';

class NotFound extends Component {

  render() {

    const { handleLogout, history } = this.props;
    console.log(history);

    return (
      <Box className="NotFound" fill align="center" justify="center">
        <p>Page does not exist.</p>
        <p>
          <Button
            icon={<Home />}
            label="Go Home"
            margin="small"
            primary={true}
            color="#3c5267"
            onClick={() => {
              handleLogout();
              history.push('/');
            }}
          />
        </p>
      </Box>
    );
  }

}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
